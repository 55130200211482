import React from 'react'
import verifyemail from '../images/verify-email.svg';
import './style.css';

function Verify_link() {
  return (
    <section className='verify-link verify_email'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-5 mx-auto'>
                    <div className='wrapping'>
                   <div className='text-center'>
                   <img src={verifyemail} alt="Verify Email" />
                   </div>
                    <h3>Réinitialiser votre mot de passe</h3>
                <p className='text-center'>Mot de passe oublié ? Veuillez saisir votre email et nous vous enverrons un lien</p>
              
                    <p></p>
                        <div className='row'>
                        <div className='col'>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Enter your email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                   
                  />
                </div>
              </div>
            
                        </div>
                       <div className='text-center'> <button type="button" class="btn btn-secondary btn-main mb-4 email-recove mt-4">Soumettre</button></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Verify_link