import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Authentication/Login";
import Dashboard from "../Dashboard";
import "../global.css";
import Guard from "./guard";
import Signup from "../Authentication/Signup";
import Index from "../Container/Index";
import Intructorprofile from "../Instructor Profile/Index";
import Slotconfirmation from "../Instructor Profile/Slotconfirmation";
import Lesson from "../Conduct/Lessons/Lesson";
import Passerpermis from "../Conduct/Passer-le-permis";
import Livret from "../Conduct/Livret d'apprentissage";
import Newreservation from "../Conduct/Passer-le-permis/Newreservation";
import Message from "../Message/Index";
import Boutique from "../Boutique";
import Addtocart from "../Boutique/Addtocart";
import Parameter from "../Parameter/index";
import Profile from "../Parameter/Profile";
import Changepassword from "../Parameter/Changepassword";
import NEPH from "../Parameter/NEPH";
import Training from "../Parameter/Training";
import Sponser from "../Parameter/Sponser";
import Legal from "../Parameter/Legal";
import Achats from "../Parameter/Achats";
import Transaction from "../Transaction";
import Subscribed_user_dashboard from "../Dashboard/Subscribed_user_dashboard";
import Premiers_secours from "../Conduct/Livret d'apprentissage/Premiers_secours";
import Check_external from "../Conduct/Livret d'apprentissage/Check_external";
import Check_internal from "../Conduct/Livret d'apprentissage/Check_internal";
import Master from "../Conduct/Livret d'apprentissage/Master";
import Circular from "../Conduct/Livret d'apprentissage/Circular";
import Practise from "../Conduct/Livret d'apprentissage/Practise";
import New_reservation_update from "../Dashboard/New_reservation_update";
import Successful from "../Transaction/Successful";
import Helpsupport from "../Parameter/Help&support";
import Activechat_support from "../Parameter/Activechat_support";
import Reportby_admin from "../Parameter/Reportby_admin";
import Invoice from "../Parameter/Invoice";
import Chat from "../Parameter/Chat";
import Myreservation from "../Parameter/Myreservation";
import Confirmation from "../Confirmation";
import Notifications from "../Notifications";
import Myevaluation from "../Parameter/Myevaluation";
import Myevaluationdetail from "../Parameter/Myevaluationdetail";
import Feedbackto_instructor from "../Notifications/Feedbackto_instructor";
import Verify_email from "../Authentication/Verify_email";
import Forgot_psd from "../Authentication/Forgot_psd";
import Welcome from "../Authentication/Welcome";
import ShowCours from "../Conduct/Livret d'apprentissage/ShowCours";
import Verify_link from "../Authentication/Verify_link";

const App = () => {
  return (
    <Router basename="/student">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Signup />} />
        <Route
          path="/dashboard"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path="/instructorprofile"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Intructorprofile />} />
        </Route>
        <Route
          path="/lessons"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Lesson />} />
        </Route>
        <Route
          path="/permis"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Passerpermis />} />
        </Route>

        <Route
          path="/livret"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Livret />} />
        </Route>
        <Route
          path="/newreservation"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Newreservation />} />
        </Route>
        <Route
          path="/message"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Message />} />
        </Route>
        <Route
          path="/boutique"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Boutique />} />
        </Route>
        <Route
          path="/feedback-instructor"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Feedbackto_instructor />} />
        </Route>
        <Route
          path="/cart"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Addtocart />} />
        </Route>
        <Route
          path="/parameter"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Parameter />} />
        </Route>
        <Route
          path="/profile"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Profile />} />
        </Route>
        <Route
          path="/changepassword"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Changepassword />} />
        </Route>
        <Route
          path="/neph"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<NEPH />} />
        </Route>
        <Route
          path="/training"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Training />} />
        </Route>
        <Route
          path="/sponser"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Sponser />} />
        </Route>
        <Route
          path="/legal"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Legal />} />
        </Route>
        <Route
          path="/achats/:id"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Achats />} />
        </Route>
        <Route
          path="/transaction"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Transaction />} />
        </Route>
        <Route
          path="/subscribed-dashboard"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Subscribed_user_dashboard />} />
        </Route>
        <Route
          path="/premierssecours"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Premiers_secours />} />
        </Route>
        <Route
          path="/check-external"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Check_external />} />
        </Route>
        <Route
          path="/invoice/:id"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Invoice />} />
        </Route>
        <Route
          path="/check-internal"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Check_internal />} />
        </Route>
        <Route
          path="/master"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Master />} />
        </Route>

        <Route
          path="/cours/:id"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<ShowCours />} />
        </Route>
        {/* 
        <Route
          path="/cours/:id"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<ShowCours />} />
        </Route> */}
        <Route
          path="/circular"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Circular />} />
        </Route>
        <Route
          path="/practise"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Practise />} />
        </Route>
        <Route
          path="/new_reservation_update"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<New_reservation_update />} />
        </Route>
        <Route
          path="/helpsupport"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Helpsupport />} />
        </Route>
        <Route
          path="/activechat"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Activechat_support />} />
        </Route>
        <Route
          path="/report-admin"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Reportby_admin />} />
        </Route>
        <Route
          path="/chat"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Chat />} />
        </Route>
        <Route
          path="/myreservation"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Myreservation />} />
        </Route>
        <Route
          path="/notifications"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Notifications />} />
        </Route>

        <Route
          path="/myevaluation"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Myevaluation />} />
        </Route>
        <Route
          path="/myevaluation-details"
          element={
            <Guard>
              <Index />
            </Guard>
          }
        >
          <Route index element={<Myevaluationdetail />} />
        </Route>
      
        <Route exact path="/verify-email" element={<Verify_email />} />
        <Route exact path="/verify_link" element={<Verify_link />} />
        <Route exact path="/slotconfirmation" element={<Slotconfirmation />} />
        <Route exact path="/transaction-successfull" element={<Successful />} />
        <Route exact path="/confirmation" element={<Confirmation />} />
        <Route exact path="/forgot-psd" element={<Forgot_psd />} />
        <Route exact path="/welcome" element={<Welcome />} />
      </Routes>
    </Router>
  );
};

export default App;
