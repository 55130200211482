import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/http/config/axios";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.css';
import userimg from '../images/user-img.png';
import uploadicon from '../images/upload-icon.png';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthDate: '',
    birthCity: '',
    number: '', 
    street: '',
    additional: '',
    postalCode: '',
    city: '',
    country: '',
    drivingCity: '',
    profilePicture: null
  });

  const [value, setValue] = useState(''); 
  const [profilePicPreview, setProfilePicPreview] = useState(userimg);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false); // Loading state for the update button

  // Fetch profile data from backend
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken'); 
        const response = await axiosInstance.get("/api/students/profile", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const data = response.data.student;
        setFormData({
          firstName: data.user.firstName || '',
          lastName: data.user.lastName || '',
          email: data.user.email || '',
          gender: data.gender || '',
          birthDate: data.birthDate || '',
          birthCity: data.birthCity || '',
          number: data.addressNumber || '',
          street: data.streetName || '',
          additional: data.additionalAddress || '',
          postalCode: data.postalCode || '',
          city: data.city || '',
          country: data.country || '',
          drivingCity: data.drivingCity || '',
          profilePicture: null,
        });

        // Store firstName and lastName in localStorage
        localStorage.setItem('user', JSON.stringify({
          firstName: data.user.firstName,
          lastName: data.user.lastName
        }));

        const profilePictureUrl = data.user.profilePicture 
          ? `${window.location.origin}/${data.user.profilePicture}` 
          : userimg;
          
        setProfilePicPreview(profilePictureUrl);
        setValue(data.user.phoneNumber || '');
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  // Handle profile picture change
  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        setAlert({ type: 'error', message: 'Type de fichier non pris en charge. Veuillez télécharger une image JPEG ou PNG.' });
        return;
      }
      setProfilePicPreview(URL.createObjectURL(file));
      setFormData(prevData => ({ ...prevData, profilePicture: file }));
    }
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Le prénom est obligatoire';
    if (!formData.lastName) newErrors.lastName = 'Le nom est obligatoire';
    if (!formData.email) newErrors.email = 'L\'adresse mail est obligatoire';
    if (!formData.gender) newErrors.gender = 'Le genre est obligatoire';
    if (!formData.birthDate) newErrors.birthDate = 'La date de naissance est obligatoire';
    if (!formData.birthCity) newErrors.birthCity = 'La ville de naissance est obligatoire';
    if (!formData.number) newErrors.number = 'Le numéro de rue est obligatoire';
    if (!formData.street) newErrors.street = 'Le nom de la rue est obligatoire';
    if (!formData.postalCode) newErrors.postalCode = 'Le code postal est obligatoire';
    if (!formData.city) newErrors.city = 'La ville est obligatoire';
    if (!formData.country) newErrors.country = 'Le pays est obligatoire';
    if (!value) newErrors.phoneNumber = 'Le numéro de téléphone est obligatoire';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); 
      try {
        const token = localStorage.getItem('authToken');
        const formDataToSubmit = new FormData();

        Object.keys(formData).forEach(key => {
          if (formData[key] !== null && formData[key] !== '') {
            formDataToSubmit.append(key, formData[key]);
          }
        });

        formDataToSubmit.append('phoneNumber', value);

        await axiosInstance.put('/api/students/profile', formDataToSubmit, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        setAlert({ type: 'success', message: 'Profil mis à jour avec succès !' });
        setTimeout(() => {
          setAlert({ type: '', message: '' });
        }, 3000);
      } catch (error) {
        if (error.response && error.response.data.message) {
          setAlert({ type: 'error', message: error.response.data.message });
        } else {
          setAlert({ type: 'error', message: 'Échec de la mise à jour du profil. Veuillez réessayer.' });
        }
        setTimeout(() => {
          setAlert({ type: '', message: '' });
        }, 3000);
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); 
  };

  return (
    <section className='userprofile'>
      <div className='container'>
        <div className='wrapping'>
          {alert.message && <div className={`alert alert-${alert.type}`}>{alert.message}</div>}
          <div className='back-btn'>
            <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z" fill="white"/>
              </svg>
              Retour
            </button>
          </div>
          <div className='top-heading-profile'>
            <h3>Informations personnelles</h3>
            
            <div className='user-img'>
              <div className='profile'>
                <img src={profilePicPreview} alt="User" />
              </div>
              <div className='upload'>
                <img
                  style={{ cursor: 'pointer' }}
                  src={uploadicon}
                  alt="Upload"
                  onClick={() => document.getElementById('profilePicInput').click()}
                />
                <input
                  type="file"
                  id="profilePicInput"
                  style={{ display: 'none' }}
                  onChange={handleProfilePicChange}
                />
              </div>
            </div>
          </div>
          <div className='form'>
            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">Prénom*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">Nom*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Adresse mail*</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="gender" className="form-label">Genre*</label>
                  <select
                    className="form-control"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="">Sélectionnez...</option>
                    <option value="Homme">Homme</option>
                    <option value="Femme">Femme</option>
                  </select>
                  {errors.gender && <div className="text-danger">{errors.gender}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="birthDate" className="form-label">Date de naissance*</label>
                  <input
                    type="date"
                    className="form-control"
                    id="birthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                  />
                  {errors.birthDate && <div className="text-danger">{errors.birthDate}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="birthCity" className="form-label">Ville de naissance*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="birthCity"
                    name="birthCity"
                    value={formData.birthCity}
                    onChange={handleChange}
                  />
                  {errors.birthCity && <div className="text-danger">{errors.birthCity}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6'>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">Numéro de téléphone*</label>
                  <PhoneInput
                    placeholder="Entrez votre numéro"
                    value={value}
                    className="form-control"
                    onChange={setValue}
                  />
                  {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                </div>
              </div>
            </div>

            <h3 className="mb-4">Adresse</h3>
            <div className="form">
              <div className="row">
                <div className="col-lg-2">
                  <div className="mb-3">
                    <label htmlFor="number" className="form-label">Numéro de rue*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="number"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                    />
                    {errors.number && <div className="text-danger">{errors.number}</div>}
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="mb-3">
                    <label htmlFor="street" className="form-label">Nom de la route*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="street"
                      name="street"
                      value={formData.street}
                      onChange={handleChange}
                    />
                    {errors.street && <div className="text-danger">{errors.street}</div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="additional" className="form-label">Adresse supplémentaire</label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional"
                      name="additional"
                      value={formData.additional}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="postalCode" className="form-label">Code Postal*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="postalCode"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                    {errors.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Ville*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors.city && <div className="text-danger">{errors.city}</div>}
                  </div>
                </div>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">Pays*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors.country && <div className="text-danger">{errors.country}</div>}
                  </div>
                </div>
              </div>
            </div>

            <h3 className="mb-4">Ville où vous souhaitez conduire</h3>
            <div className="form">
              <div className='row'>
                <div className='col-lg-6'>
                  <div className="mb-3">
                    <label htmlFor="drivingCity" className="form-label">Elle peut être différente de votre adresse</label>
                    <input
                      type="text"
                      className="form-control"
                      id="drivingCity"
                      name="drivingCity"
                      value={formData.drivingCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-btn">
              <button type="button" className="btn btn-secondary btn-main" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Mise à jour...' : 'Mettre à jour'}
              </button>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
