import React, { useEffect, useState } from 'react';
import staer from '../images/staer.png';
import newnoti from '../images/new-noti.svg';
import calcnoti from '../images/calcnoti.svg';
import './style.css';
import { useNavigate } from 'react-router-dom';

// Helper function to fetch notifications
const fetchNotifications = async (token) => {
  try {
    const response = await fetch('/api/students/notifications', {
      headers: {
        'Authorization': `Bearer ${token}`,  
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('La réponse réseau n\'était pas correcte');
    }

    const data = await response.json();
    return data.notifications;  
  } catch (error) {
    console.error('Erreur lors de la récupération des notifications :', error);
    return [];
  }
};

// Define notification icons based on type
const notificationIcons = {
  reservation: calcnoti,
  notification: staer,
  rappel: newnoti,
  alerte: newnoti,
  paiement: calcnoti,
};

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      fetchNotifications(token)
        .then(data => {
          setNotifications(data);
          setLoading(false);
        })
        .catch(err => {
          setError('Échec lors de la récupération des notifications');
          setLoading(false);
        });
    } else {
      setError('Aucun jeton d\'authentification trouvé');
      setLoading(false);
    }
  }, []);

  // Function to generate URL based on notification type
  const getUrlByType = (type) => {
    switch (type) {
      case 'reservation':
        return '/myreservation';
      case 'notification':
        return '/notifications'; 
      case 'rappel':
        return '/rappel';
      case 'alerte':
        return '/alertes';
      case 'paiement':
        return '/transaction';
      default:
        return '/';
    }
  };

  // Function to handle notification click
  const handleNotificationClick = (type) => {
    const url = getUrlByType(type); 
    navigate(url); 
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const goBack = () => {
    navigate(-1); 
  };

  return (
    <section className='notifications'>
      <div className='container'>
        <div className='wrapping'>
          <div className='d-flex justify-content-end'>
            <button type="button" onClick={goBack} class="btn btn-secondary btn-main mb-3">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white"/>
              </svg>
              Retour
            </button>
          </div>
          <h5>Nouvelles notifications</h5>
          <hr />
          {notifications.length === 0 ? (
            <p>Aucune notification disponible</p>
          ) : (
            notifications.map((noti, index) => (
              <div 
                key={index} 
                className='noti-recent' 
                onClick={() => handleNotificationClick(noti.type)} 
                style={{ cursor: 'pointer' }}
              >
                <div className='d-flex'>
                  <img src={notificationIcons[noti.type] || staer} alt={noti.type} />
                  <div className='ps-3'>
                    <p className='mb-0'><strong>{noti.title}</strong> {noti.message}</p>
                    <div className='date-time'>
                      <span>{new Date(noti.createdAt).toLocaleDateString()}</span>
                      <span>{new Date(noti.createdAt).toLocaleTimeString()}</span>
                    </div>
                  </div>
                </div>
                {noti.isNew && (
                  <div className='new-noti'>
                    <img src={newnoti} alt="Nouvelle notification" />
                  </div>
                )}
                <hr />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
}

export default Notifications;
