import { useState, useEffect } from 'react';
import axiosInstance from "../utils/http/config/axios";
import dashboardicon from "../images/dashboard-icon.png";
import assign_inst from "../images/assign-inst.png";
import './style.css';
import Progress_chart from './Progress_chart';
import uiw_enter from '../images/uiw_enter.png';
import instruct from '../images/instruct.png';
import clarity_clock_line from '../images/clarity_clock-line.svg';

function Subscribed_user_dashboard() {
    const [userName, setUserName] = useState("");
    const [nextLesson, setNextLesson] = useState(null);
    const [yesterdayLessons, setYesterdayLessons] = useState([]);
    const [hoursConsumedYesterday, setHoursConsumedYesterday] = useState(0);
    const [hoursRemaining, setHoursRemaining] = useState(0); 
    const [assignedInstructor, setAssignedInstructor] = useState(null); 

    useEffect(() => {
        // Récupérer les informations utilisateur de localStorage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setUserName(`${user.firstName} ${user.lastName}`);
        }

        // Fonction pour récupérer les données de l'étudiant
        const fetchStudentData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token non trouvé');
                }

                // Récupérer les données du tableau de bord pour les leçons
                const dashboardResponse = await axiosInstance.get('/api/students/dashboard', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const { nextLesson: upcomingLesson = null, lessons: pastLessons = [], assignedInstructor, hoursRemaining } = dashboardResponse.data.data || {};
                setNextLesson(upcomingLesson);
                setAssignedInstructor(assignedInstructor); 
                setHoursRemaining(hoursRemaining); 

                // Filtrer les leçons d'hier
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const formattedYesterday = yesterday.toLocaleDateString();

                const lessonsYesterday = pastLessons.filter(lesson =>
                    new Date(lesson.timeslot.date).toLocaleDateString() === formattedYesterday
                );

                setYesterdayLessons(lessonsYesterday);

                // Calculer les heures consommées hier
                const totalHoursConsumed = lessonsYesterday.reduce((sum, lesson) => sum + lesson.duration, 0);
                setHoursConsumedYesterday(totalHoursConsumed / 60); 

            } catch (err) {
                console.error('Erreur lors de la récupération des données des leçons ou du profil', err);
            }
        };

        fetchStudentData();
    }, []);

    return (
        <section className='subcribed'>
            <div className='container-fluid'>
                <div className="mt-5">
                    <div className="row dashboard-wrap">
                        <div className="col-lg-8">
                            <div className="content-wrap">
                                <h2>
                                    Bienvenue! <span>{userName}</span>
                                </h2>
                                <p>Content de te revoir</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img src={dashboardicon} alt="Icône du tableau de bord" />
                        </div>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-lg-4'>
                        <div className='total-hrs'>
                            <div className='driving-sessions'>
                            <button type="button" class="btn btn-secondary btn-main mb-3">Nouvelle réservation</button>
                                <span>Vos séances de conduite</span>
                                <h5>Heures de conduite</h5>
                                <div className='d-flex hrs'>
                                    <h2>{hoursRemaining} hrs</h2><p>restantes</p>
                                </div>
                                <hr></hr>
                                <p className='assign-inst'>Instructeur assigné</p>
                                {assignedInstructor ? (
                                    <div className='d-flex inst-img'>
                                        <img src={assign_inst} alt="Instructeur assigné" />
                                        <strong>{`${assignedInstructor.user?.firstName} ${assignedInstructor.user?.lastName}`}</strong>
                                    </div>
                                ) : (
                                    <p className='mb-0'>Aucun instructeur assigné.</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-8'>
                        <div className='chat-progress'>
                            <div className='d-flex justify-content-between'>
                                <h3>Votre progression</h3>
                                <button type="button" className="btn btn-dark">Voir le rapport</button>
                            </div>
                            <Progress_chart />
                        </div>
                    </div>
                </div>

                <div className='row mt-4 my-5'>
                    <div className='col-lg-8'>

                        {nextLesson && (
                            <div className='comign-lesson'>
                                <h4>Leçon à venir</h4>
                                <div className='current'>
                                    <img src={uiw_enter} alt="Icône de leçon" /> 
                                    <p>Leçon <span>{nextLesson.id}</span></p>
                                    <div className='inst'>
                                        <img src={instruct} alt="Instructeur" />
                                        <div className='ps-2'>
                                            <strong>{`${nextLesson.timeslot.instructor?.user?.firstName} ${nextLesson.timeslot.instructor?.user?.lastName}`}</strong><br />
                                            <span>Instructeur</span>
                                        </div>
                                    </div>
                                    <div className='clock'>
                                        <img src={clarity_clock_line} alt="Icône Horloge" /> 
                                        <strong>{nextLesson.timeslot.startTime}</strong>
                                    </div>
                                    <div className='duration'>
                                        <p>Durée: <span>{(nextLesson.duration / 60).toFixed(2)} Hrs</span></p>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className='col-lg-4'>
                        <div className='yesterday-consume-hrs'>
                            <h3>Heures de conduite consommées hier</h3>
                            <div className='consume_hrs'>
                                <strong>{Math.floor(hoursConsumedYesterday)} hr{Math.floor(hoursConsumedYesterday) !== 1 ? 's' : ''}</strong>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Subscribed_user_dashboard;
