import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/http/config/axios";
import { useNavigate } from "react-router-dom";
import lesbg from "../../images/girl_drive.png";
import lessons_with_inst from "../../images/lessons-with-inst.png";
import instructor from "../../images/instructor-person.png";
import inst_icon from "../../images/inst-icon.png";
import "../style.css";
import Pagination from 'react-bootstrap/Pagination';

function Lesson() {
  const [nextLesson, setNextLesson] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [totalHoursUsed, setTotalHoursUsed] = useState(0);
  const [assignedInstructor, setAssignedInstructor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [hoursRemaining, setHoursRemaining] = useState(0);  
  const navigate = useNavigate();
  const hasSubscription = localStorage.getItem("hasSubscription") === "true";

  useEffect(() => {
    const fetchStudentDashboard = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const dashboardResponse = await axiosInstance.get("/api/students/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (dashboardResponse.data.success) {
          const { lessons, assignedInstructor, totalHoursUsed, hoursRemaining } = dashboardResponse.data.data;
          const upcomingLesson = lessons.find(lesson => new Date(lesson.timeslot.date) >= new Date());

          setLessons(lessons);
          setNextLesson(upcomingLesson);
          setTotalHoursUsed(totalHoursUsed);
          setAssignedInstructor(assignedInstructor);
          setHoursRemaining(hoursRemaining);  
        } else {
          setError(dashboardResponse.data.message || "Aucune leçon trouvée");
        }
      } catch (err) {
        setError("Une erreur est survenue lors de la récupération des leçons et du profil");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDashboard();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!hasSubscription) {
    return (
      <section className="lesson h-100">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column align-items-center justify-content-center">
            <div className="wraping">
              <h2>Acheter des <span>heures</span></h2>
              <p>Pour réserver un cours, pensez à acheter des heures de conduite.</p>
              <button type="button" className="btn btn-secondary btn-main" onClick={() => navigate('/boutique')}>
                Acheter maintenant
              </button>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={lesbg} alt="Leçons" />
          </div>
        </div>
      </section>
    );
  }

  const indexOfLastLesson = currentPage * itemsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - itemsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);
  const totalPages = Math.ceil(lessons.length / itemsPerPage);

  return (
    <>
      <section className="lesson h-100 subscribed_user">
        <div className="subscribed_user_view">
          <div className="row">
            <div className="col-lg-7">
              <div className="wraping">
                <h2>Leçons à venir <span>venir</span></h2>
                {nextLesson ? (
                  <>
                    <p><span>Autres détails</span></p>
                    <p>Votre prochain cours commencera à <strong>{nextLesson.timeslot.startTime}</strong></p>
                    <p>Instructeur: <strong>{`${nextLesson.timeslot.instructor?.user?.firstName} ${nextLesson.timeslot.instructor?.user?.lastName}`}</strong></p>
                  </>
                ) : (
                  <p>Aucune leçon à venir.</p>
                )}
              </div>
            </div>
            {nextLesson && (
              <div className="col-lg-5">
                <div className="d-flex justify-content-center">
                  <img src={lessons_with_inst} alt="Lessons" />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="bottom-widgets">
        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-lg-4">
              <div className="credits">
                <strong>Crédit disponible</strong>
                <h3>{hoursRemaining} heures</h3>  {/* Display hoursRemaining */}
                <span>disponible</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="credits appre">
                <strong>Apprentissage</strong>
                <h3>{nextLesson ? '20%' : '0%'}</h3>
                <p>Suivez l'évolution de votre apprentissage dans votre livret en ligne</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="credits ense">
                {assignedInstructor && (
                  <div className="d-flex align-items-center mt-3">
                    <img src={assignedInstructor.user?.profilePicture || instructor} alt="Instructor" />
                    <h4>{`${assignedInstructor.user?.firstName} ${assignedInstructor.user?.lastName}`}</h4>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="credits utilize mt-4">
                <strong>Crédit utilisé</strong>
                <h3>{totalHoursUsed} heures</h3>
                <span>crédit déjà utilisé</span>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <h2 className="mb-4"><strong>Récapitulatif</strong></h2>
          </div>
          <div className="row">
            <div className="summary-table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Statut</th>
                    <th scope="col">Instructeur</th>
                    <th scope="col">Date</th>
                    <th scope="col">Horaires</th>
                    <th scope="col">Adresse</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentLessons.length > 0 ? (
                    currentLessons.map((lesson) => (
                      <tr key={lesson.id}>
                        <td><p className="mb-0">{lesson.status}</p></td>
                        <td>
                          <img src={inst_icon} className="me-2" alt="Instructor" />
                          {lesson.timeslot.instructor?.user?.firstName} {lesson.timeslot.instructor?.user?.lastName}
                        </td>
                        <td>{new Date(lesson.timeslot.date).toLocaleDateString()}</td>
                        <td>{lesson.timeslot.startTime} - {lesson.timeslot.endTime}</td>
                        <td>{lesson.location || 'Aucune adresse fournie'}</td>
                        <td>
                          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9.57617" cy="9.5" r="9.5" fill="url(#paint0_linear_2327_6099)" />
                            <path d="M8.57598 5.5L7.87598 6.25L11.576 10L7.87598 13.75L8.57598 14.5L13.076 10L8.57598 5.5Z" fill="white" />
                            <defs>
                              <linearGradient id="paint0_linear_2327_6099" x1="1.04861" y1="5.62245" x2="18.395" y2="7.15996" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C43CAB" />
                                <stop offset="1" stopColor="#F87E4B" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">Aucune leçon trouvée.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Pagination className='mb-0'>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages)].map((_, pageIndex) => (
              <Pagination.Item
                key={pageIndex + 1}
                active={currentPage === pageIndex + 1}
                onClick={() => handlePageChange(pageIndex + 1)}
              >
                {pageIndex + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        </div>
      </section>
    </>
  );
}

export default Lesson;
