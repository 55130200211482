import React, { useState, useEffect } from "react";
import "./style.css";
import "../global.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import blind from "../images/blind.svg";
import eye from "../images/ph_eye.svg";
import gmplogo from "../images/brand_logo.svg";
import imglogin from "../images/img-login.png";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(blind);
  const [error, setError] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || "");
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberMeEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? eye : blind);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    if (!isTermsAccepted) {
      setError("Vous devez accepter les termes et conditions pour vous connecter.");
      toast.error("Veuillez accepter les termes et conditions.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await axiosInstance.post("/api/students/login", {
        email,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const { token, role, hasSubscription, dashboard } = response.data;

      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("dashboard", dashboard);
      localStorage.setItem("hasSubscription", hasSubscription);

      if (rememberMe) {
        localStorage.setItem("rememberMeEmail", email);
      } else {
        localStorage.removeItem("rememberMeEmail");
      }

      navigate(dashboard);

      toast.success("Connexion réussie !", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });

    } catch (error) {
      setError(error.response?.data?.error || "Une erreur inattendue s'est produite. Veuillez réessayer.");
      toast.error(error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <section className="login">
      <div className="container-fluid mx-0">
        <div className="row">
          <div className="col-lg-6 p-0">
            <div className="wrap">
              <div className="login-content">
                <h3>Connexion</h3>
                <p>Connectez-vous à votre compte</p>
                <ToastContainer />
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {error && <div className="alert alert-danger">{error}</div>}
                
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  
                  <div className="mb-3 password">
                    <input
                      type={type}
                      className="form-control password"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span
                      className="flex justify-around items-center psd-icon"
                      onClick={handleToggle}
                    >
                      <img src={icon} alt="Basculer la visibilité" />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Souviens-toi de moi
                      </label>
                    </div>
                    <div>
                      <Link to="/forgot-psd" style={{color: 'red'}}>réinitialisation</Link>
                    </div>
                  </div>
                  
                  <div className="aggrement my-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={isTermsAccepted}
                        onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                      />
                      <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                        je suis d'accord avec les termes et conditions
                      </label>
                    </div>
                  </div>

                  <div className="login-btn">
                    <button
                      type="submit"
                      className="btn btn-primary btn-fill"
                    >
                      Se connecter
                    </button>
                  </div>
                  
                  <p className="my-4">Vous n'avez pas de compte ? Veuillez vous <Link to="/register" style={{color: '#F85E1E'}}>inscrire</Link></p>
                </form>
              </div>
            </div>
          </div>
          
          <div className="col-lg-6 p-0">
            <div className="right-side-bg">
              <img className="side-img-login" src={imglogin} alt="Login" />
              <div className="toplogo">
                <img src={gmplogo} alt="Logo" />
              </div>
              <div className="img-btm-text">
                <p>Développez vos compétences de conduite</p>
                <h2>
                  Apprenez à conduire en toute <span>confiance</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
